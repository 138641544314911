exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-businesses-businessloans-jsx": () => import("./../../../src/pages/businesses/businessloans.jsx" /* webpackChunkName: "component---src-pages-businesses-businessloans-jsx" */),
  "component---src-pages-businesses-platform-jsx": () => import("./../../../src/pages/businesses/platform.jsx" /* webpackChunkName: "component---src-pages-businesses-platform-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-crypto-loan-aave-loan-jsx": () => import("./../../../src/pages/crypto-loan/aave-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-aave-loan-jsx" */),
  "component---src-pages-crypto-loan-btc-loan-jsx": () => import("./../../../src/pages/crypto-loan/btc-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-btc-loan-jsx" */),
  "component---src-pages-crypto-loan-cbbtc-loan-jsx": () => import("./../../../src/pages/crypto-loan/cbbtc-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-cbbtc-loan-jsx" */),
  "component---src-pages-crypto-loan-comp-loan-jsx": () => import("./../../../src/pages/crypto-loan/comp-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-comp-loan-jsx" */),
  "component---src-pages-crypto-loan-eth-loan-jsx": () => import("./../../../src/pages/crypto-loan/eth-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-eth-loan-jsx" */),
  "component---src-pages-crypto-loan-jsx": () => import("./../../../src/pages/crypto-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-jsx" */),
  "component---src-pages-crypto-loan-link-loan-jsx": () => import("./../../../src/pages/crypto-loan/link-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-link-loan-jsx" */),
  "component---src-pages-crypto-loan-page-jsx": () => import("./../../../src/pages/crypto-loan/page.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-page-jsx" */),
  "component---src-pages-crypto-loan-tbtc-loan-jsx": () => import("./../../../src/pages/crypto-loan/tbtc-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-tbtc-loan-jsx" */),
  "component---src-pages-crypto-loan-uni-loan-jsx": () => import("./../../../src/pages/crypto-loan/uni-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-uni-loan-jsx" */),
  "component---src-pages-crypto-loan-wbtc-loan-jsx": () => import("./../../../src/pages/crypto-loan/wbtc-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-wbtc-loan-jsx" */),
  "component---src-pages-crypto-loan-wsteth-loan-jsx": () => import("./../../../src/pages/crypto-loan/wsteth-loan.jsx" /* webpackChunkName: "component---src-pages-crypto-loan-wsteth-loan-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-learn-jsx": () => import("./../../../src/pages/learn.jsx" /* webpackChunkName: "component---src-pages-learn-jsx" */),
  "component---src-pages-learn-markdown-remark-fields-slug-jsx": () => import("./../../../src/pages/learn/{MarkdownRemark.fields__slug}.jsx" /* webpackChunkName: "component---src-pages-learn-markdown-remark-fields-slug-jsx" */),
  "component---src-pages-learn-search-jsx": () => import("./../../../src/pages/learn/search.jsx" /* webpackChunkName: "component---src-pages-learn-search-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-referral-jsx": () => import("./../../../src/pages/referral.jsx" /* webpackChunkName: "component---src-pages-referral-jsx" */),
  "component---src-pages-referral-program-terms-jsx": () => import("./../../../src/pages/referral-program-terms.jsx" /* webpackChunkName: "component---src-pages-referral-program-terms-jsx" */),
  "component---src-pages-refinance-jsx": () => import("./../../../src/pages/refinance.jsx" /* webpackChunkName: "component---src-pages-refinance-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-waitlist-confirmation-tsx": () => import("./../../../src/pages/waitlist-confirmation.tsx" /* webpackChunkName: "component---src-pages-waitlist-confirmation-tsx" */),
  "component---src-pages-waitlist-jsx": () => import("./../../../src/pages/waitlist.jsx" /* webpackChunkName: "component---src-pages-waitlist-jsx" */),
  "component---src-pages-why-defi-tsx": () => import("./../../../src/pages/why-defi.tsx" /* webpackChunkName: "component---src-pages-why-defi-tsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

